import {
    ChangeDetectionStrategy,
   Component,
   EventEmitter,
   Input,
   OnChanges,
   OnInit,
   Output,
   SimpleChanges
} from '@angular/core'
import { FormControl, FormGroup, Validators, FormsModule, ReactiveFormsModule } from '@angular/forms'
import { AccountsService, CommonService } from '@appShared/services'
import { MustMatchValidator } from '@appShared/validators/must-match.validator'
import { BsDatepickerConfig, BsDatepickerModule } from 'ngx-bootstrap/datepicker'
import { EducationLevels, EducationLevel_ } from '@appShared/services/lookup/[CodeGen]/education-level.domain'
import { IProfile } from '@appShared/interfaces/[Model-based]/profile.interface'
import { IAccount } from '@appShared/interfaces/[Model-based]/account.interface'
import { IProfileSubmission } from '@appShared/interfaces/[Model-based]/profile-submission.interface'
import { InstitutionType_, InstitutionTypes } from '@appShared/services/lookup/[CodeGen]/institution-type.domain'
import { ButtonComponent } from '../../../../shared/components/button/button.component';
import { NgSelectModule } from '@ng-select/ng-select';
import { NgIf, NgClass, NgFor } from '@angular/common';

@Component({
    selector: 'app-account-student',
    templateUrl: './student.component.html',
    styleUrls: ['./student.component.less'],
    standalone: true,
    imports: [NgIf, FormsModule, ReactiveFormsModule, NgClass, BsDatepickerModule, NgSelectModule, NgFor, ButtonComponent]
})
export class AccountStudentComponent
   implements OnInit, OnChanges {
   @Input() profile: IProfile
   @Input() canEdit: boolean
   @Output() profileCreatedUpdated = new EventEmitter()
   @Output() cancelProfile = new EventEmitter()

   private _newProfile: IProfile

   // formgroup/fields
   profileSubmissionForm: FormGroup
   emailAddress: FormControl
   nickname: FormControl
   nicknameMessage: string
   nicknameMask: RegExp[]
   useNickname: boolean
   firstName: FormControl
   lastName: FormControl
   datePickerBaseConfig: Partial<BsDatepickerConfig> = {}
   dateOfBirthPicker: FormControl
   dateOfBirthMinDate: Date
   dateOfBirthMaxDate: Date
   // not using graduationYear level at the moment
   //graduationYear: FormControl
   //graduationYearMask: RegExp[]
   educationLevelSelect: FormControl
   educationLevels = EducationLevels.filter(level => level.code >= EducationLevel_.Five)
   // https://egghead.io/lessons/angular-create-a-formcontrol-dynamically-with-reactive-forms-in-angular
   institutionTypeSelectFieldName = 'institutionTypeCode';
   institutionTypeSelect: any = null;
   institutionTypes = InstitutionTypes.filter(type => [
      InstitutionType_.Public,
      InstitutionType_.Private,
      InstitutionType_.Homeschool].includes(type.code))

   password: FormControl
   optionalPassword: FormControl
   passwordMessage: string
   confirmPassword: FormControl
   optionalConfirmPassword: FormControl
   mouseoverProfileSubmissionSubmit: boolean
   isNewRecord: boolean
   isSubmitting: boolean

   constructor(
      //private _appFacade: AppFacade,
      private _commonService: CommonService,
      private _accountsService: AccountsService
   ) {
      this._newProfile = _accountsService.getNewProfile()
      this.passwordMessage = _commonService.regexp().passwordMessage
      this.nicknameMessage = _commonService.regexp().usernameMessage
      this.nicknameMask = _commonService.mask().username
      //this.graduationYearMask = [/\d/,/\d/,/\d/,/\d/]
   }

   ngOnInit(): void {
      this.datePickerBaseConfig = this._commonService.datePickerBaseConfig
      let currentDate = this._commonService.dateTime.moment().startOf('day')
      this.dateOfBirthMinDate = currentDate.clone().add(-50, 'years')._d
      this.dateOfBirthMaxDate = currentDate.clone().add(-10, 'years')._d

      /* Add "Not Specified" (0) option at end of list */
      this.educationLevels.push(EducationLevels.find(level => level.code >= EducationLevel_.NotSpecified))

      this._createFormGroup()
   }

   ngOnChanges(changes: SimpleChanges): void {
      if (
         changes &&
         changes['profile'] &&
         changes['profile'].currentValue &&
         changes['profile'].previousValue?.id != changes['profile'].currentValue.id
      ) {
         this._setProfileData()
      }
   }

   /*
    * events
    */

   /*
    * private methods
    * */

   private _createFormGroup(): void {
      this.firstName = new FormControl(null, Validators.required)
      this.lastName = new FormControl(null, Validators.required)
      this.emailAddress = new FormControl(null, [
         Validators.required,
         Validators.pattern(this._commonService.regexp().email)
      ])
      this.nickname = new FormControl(null, [
         Validators.required,
         Validators.pattern(this._commonService.regexp().username)
      ])
      this.dateOfBirthPicker = new FormControl(null)
      // not using graduationYear level at the moment
      //this.graduationYear = new FormControl(null, [
      //   Validators.pattern(/^(19[8-9]\d|20[0-3]\d|2040)$/)/*valid:1980-2040*/
      //])
      this.educationLevelSelect = new FormControl(null, Validators.required)
      this.password = new FormControl(null, [
         Validators.required,
         Validators.pattern(this._commonService.regexp().password),
         MustMatchValidator('confirmPassword', true)
      ])
      this.confirmPassword = new FormControl(null, [Validators.required, MustMatchValidator('password')])

      this.optionalPassword = new FormControl(null, [
         Validators.pattern(this._commonService.regexp().password),
         MustMatchValidator('optionalConfirmPassword', true)
      ])
      this.optionalConfirmPassword = new FormControl(null, [MustMatchValidator('optionalPassword')])

      this.profileSubmissionForm = new FormGroup({
         emailAddress: this.emailAddress,
         nickname: this.nickname,
         firstName: this.firstName,
         lastName: this.lastName,
         dateOfBirthPicker: this.dateOfBirthPicker,
         // not using graduationYear at the moment
         //graduationYear: this.graduationYear
         educationLevelSelect: this.educationLevelSelect,
         [this.institutionTypeSelectFieldName]: new FormControl(
            null,
            Validators.required
         ),
         password: this.password,
         confirmPassword: this.confirmPassword,
         optionalPassword: this.optionalPassword,
         optionalConfirmPassword: this.optionalConfirmPassword
      })
   }

   private _setProfileData(): void {

      this.isSubmitting = false

      this.profileSubmissionForm.clearValidators()
      this.institutionTypeSelect = null
      this.profileSubmissionForm.reset()
      this.profileSubmissionForm.markAsPristine()
      this.profileSubmissionForm.markAsUntouched()

      this.profile = this.profile ?? { ...this._newProfile }

      this.isNewRecord = !(this.profile.id)

      if (this.isNewRecord) {
         this.optionalPassword.setValue(null)
         this.optionalPassword.disable()
         this.optionalConfirmPassword.setValue(null)
         this.optionalConfirmPassword.disable()
         this.password.enable()
         this.confirmPassword.enable()
      } else {
         this.password.setValue(null)
         this.password.disable()
         this.confirmPassword.setValue(null)
         this.confirmPassword.disable()
         this.optionalPassword.enable()
         this.optionalConfirmPassword.enable()
      }

      if (!this.profile.info.isEducationLevelStale) {
         const educationLevelCode = this.profile?.educationLevelCode
         this._commonService.setFormControlSelect(
            educationLevelCode,
            this.educationLevels,
            this.educationLevelSelect,
            null,
            true/* allowZero */
         )
      }

      const institutionTypeCode = this.profile.institutionTypeCode

      if (institutionTypeCode) {
         this._commonService.setDynamicFormControlSelect(
            this,
            'institutionTypeSelect',
            this.profileSubmissionForm.controls[this.institutionTypeSelectFieldName],
            this.institutionTypes,
            institutionTypeCode,
            null,
            false
         )
      }

      this.password.updateValueAndValidity()
      this.confirmPassword.updateValueAndValidity()
      this.optionalPassword.updateValueAndValidity()
      this.optionalConfirmPassword.updateValueAndValidity()
      this.educationLevelSelect.updateValueAndValidity()

      const isLocalIdentity = this.profile.info.isLocalIdentity
      const profileContact = this.profile.contact
      const emailAddress = isLocalIdentity ? '' : profileContact?.emailAddress
      const nickname = isLocalIdentity ? profileContact?.info?.nickname : ''

      const dateOfBirth = this.profile.dateOfBirth

      this.useNicknameInstead(!!(isLocalIdentity))

      this.profileSubmissionForm.patchValue({
         emailAddress,
         firstName: profileContact?.firstName,
         lastName: profileContact?.lastName,
         nickname,
         dateOfBirthPicker: dateOfBirth ? new Date(dateOfBirth) : null,
         // not using graduationYear at the moment
         //graduationYear: this.profile.graduationYear
      })
   }

   /*
    * public methods
    * */

   clearForm(): void {
      this._setProfileData()
   }

   newProfile(): void {
      this.profileCreatedUpdated.emit({ ...this._newProfile })
   }

   useNicknameInstead(useNickname: boolean): void {
      this.useNickname = useNickname

      if (useNickname) {
         this.nickname.enable()
         this.emailAddress.disable()
         this.emailAddress.setValue(null)
      } else {
         this.emailAddress.enable()
         this.nickname.disable()
         this.nickname.setValue(null)
      }

      this.emailAddress.updateValueAndValidity()
      this.nickname.updateValueAndValidity()

      this.profileSubmissionForm.clearValidators()
   }

   updateProfile(): void {
      /*
      const membershipPlanCode =
            membershipPlanFormValues[this.membershipPlanSelectFieldName]
      */

      if (this.profileSubmissionForm.valid) {

         this.isSubmitting = true

         const profileSubmissionValues = this.profileSubmissionForm.value
         const educationLevelCode: EducationLevel_ = profileSubmissionValues.educationLevelSelect?.code
         let password = profileSubmissionValues.password

         /**
          * if existing record - set password if they entered it
          * */
         if (!this.isNewRecord && !!(profileSubmissionValues.optionalPassword) && !!(profileSubmissionValues.optionalConfirmPassword)) {
            password = profileSubmissionValues.optionalPassword
         }

         const profileSubmission = {
            firstName: profileSubmissionValues.firstName,
            lastName: profileSubmissionValues.lastName,
            userLogonName: this.useNickname
               ? profileSubmissionValues.nickname
               : profileSubmissionValues.emailAddress,
            dateOfBirth: this._commonService.dateTime.formatDate(
               profileSubmissionValues.dateOfBirthPicker
            ),
            /*only set to education to form value it actually changed*/
            educationLevelCode: this.educationLevelSelect.dirty || (educationLevelCode || educationLevelCode === EducationLevel_.NotSpecified)
               ? educationLevelCode
               : null,
            institutionTypeCode: profileSubmissionValues.institutionTypeCode,
            password
         } as IProfileSubmission

         this._accountsService
            .createUpdateProfile(profileSubmission, this.profile.id)
            .then((account: IAccount) => {
               const msgSuffix = this.profile.id ? 'updated' : 'created'
               this._commonService.messageUser(`<div class="text-center fs-5 text-uppercase">User ${msgSuffix}!</div>`)

               this.profileCreatedUpdated.emit()

               this.cancel()
            })
            .finally(() => this.isSubmitting = false)
      }
   }

   cancel(): void {

      this.cancelProfile.emit()
   }
}
