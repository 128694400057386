import {
   ChangeDetectionStrategy,
   Component,
   OnInit,
   ViewEncapsulation
} from '@angular/core'
import { NgClass, NgIf } from '@angular/common'
import { FormControl, FormGroup, Validators, FormsModule, ReactiveFormsModule } from '@angular/forms'
import { ActivatedRoute, Router } from '@angular/router'
import { Title } from '@angular/platform-browser'
import { EMPTY, Observable, Subject, catchError, switchMap, take } from 'rxjs'
import { AccountsService} from '@appShared/services/accounts.service'
import { AuthService } from '@appShared/services/auth.service'
import { CommonService } from '@appShared/services/common.service'
import { MustMatchValidator } from '@appShared/validators/must-match.validator'
import { IProfile } from '@appShared/interfaces/[Model-based]/profile.interface'
import { IProfileSubmission } from '@appShared/interfaces/[Model-based]/profile-submission.interface'
import { ButtonComponent } from '@appShared/components'
import * as _ from 'lodash'
import { environment } from '@appEnvironments/environment'


@Component({
    selector: 'app-sign-up',
    templateUrl: './sign-up.component.html',
    styleUrls: ['./sign-up.component.less'],
    encapsulation: ViewEncapsulation.None,
    standalone: true,
    imports: [NgClass, FormsModule, ReactiveFormsModule, NgIf, ButtonComponent]
})
export class SignUpComponent implements OnInit {
   private _routes = environment.routes
   private _registrationUri: string

   profile: IProfile
   // formgroup/fields
   profileSubmissionForm: FormGroup
   emailAddress: FormControl
   firstName: FormControl
   lastName: FormControl
   password: FormControl
   passwordMessage: string
   confirmPassword: FormControl
   mouseoverProfileSubmissionSubmit: boolean
   errorMessage: string

   token: string
   isLoading = true
   isSubmitting: boolean

   constructor(
      private _commonService: CommonService,
      private _accountsService: AccountsService,
      private _authService: AuthService,
      private _activatedRoute: ActivatedRoute,
      private _router: Router,
      titleService: Title
   ) {
      this.token = this._activatedRoute.snapshot.queryParamMap.get('token') || ''

      if (!this.token) {
         this._router.navigate([this._routes.uri])
         return
      }

      titleService.setTitle(`LEAD - ${this._routes.signUp.title}`)

      this._registrationUri = `${this._routes.registration.uri}?token=${this.token}`
      this.profile = _accountsService.getNewProfile()
      this.passwordMessage = _commonService.regexp().passwordMessage
   }

   ngOnInit(): void {
      this._createFormGroup()

      this.isLoading = false
   }

   /*
   * private methods
   * */
   private _createFormGroup(): void {
      this.firstName = new FormControl(null, Validators.required)
      this.lastName = new FormControl(null, Validators.required)
      this.emailAddress = new FormControl(null, [
         Validators.required,
         Validators.pattern(this._commonService.regexp().email)
      ])
      this.password = new FormControl(null, [
         Validators.required,
         Validators.pattern(this._commonService.regexp().password),
         MustMatchValidator('confirmPassword', true)
      ])
      this.confirmPassword = new FormControl(null, [Validators.required, MustMatchValidator('password')])

      this.profileSubmissionForm = new FormGroup({
         emailAddress: this.emailAddress,
         firstName: this.firstName,
         lastName: this.lastName,
         password: this.password,
         confirmPassword: this.confirmPassword
      })
   }

   /*
   * public methods
   * */

   register() {
      if (this.profileSubmissionForm.valid) {

         this.isSubmitting = true

         const profileSubmissionValues = this.profileSubmissionForm.value
         const firstName = profileSubmissionValues.firstName
         const lastName = profileSubmissionValues.lastName
         const emailAddress = profileSubmissionValues.emailAddress
         const password = profileSubmissionValues.password

         const profileSubmission = {
            firstName,
            lastName,
            userLogonName: emailAddress,
            password
         } as IProfileSubmission

         let logError = (err): Observable<never> => {
            this.isSubmitting = false
            this.errorMessage = err
            console.error(`Error Occurred: ${err}`)
            return EMPTY
         }

         this._accountsService
            .createUserWithToken(this.token, profileSubmission).pipe(
               take(1),
               switchMap(userPrincipalId => this._authService.delegateLogin(userPrincipalId)),
               catchError(error => logError(error))
            )
            .subscribe((resp) => {
               this._commonService.messageUser('<div class="text-center fs-5 text-uppercase">User created!</div>')
               this.signIn(true)
            })
      }
   }

   signIn(overrideSubmit?: boolean) {
      if (!this.isSubmitting || overrideSubmit) this._commonService.navigateToLogin(`/${this._registrationUri}`)
   }
}
