import { ChangeDetectionStrategy, Component, DestroyRef, effect, inject, signal, TrackByFunction } from '@angular/core'
import { NgIf, NgFor, AsyncPipe, DatePipe } from '@angular/common'
import { FormControl, FormGroup, Validators, FormsModule, ReactiveFormsModule } from '@angular/forms'
import { Router, RouterLink } from '@angular/router'
import { HttpStatusCode } from '@angular/common/http'
import { takeUntilDestroyed, toSignal } from '@angular/core/rxjs-interop'
import { Observable, tap } from 'rxjs'
import { HybridWebviewInteropService } from '@appShared/services/hybrid-webview-interop.service'
import { UrlService } from '@appShared/services/url.service'
import { ToastrType } from '@appShared/services/toastr.service'
import { AccountsService, CommonService } from '@appShared/services'
import { ConfirmOptions, ConfirmService } from '@appShared/components/confirm-modal-and-service'
import { AppFacade } from '@appShared/services/app.facade'
import { ISubscription, ISubscriptionUpdateSubmission } from '@appShared/interfaces/[Model-based]'
import {
   BillingFrequency_,
   CancellationReason_,
   CancellationReasons,
   SubscriptionStatus_
} from '@appShared/services/lookup/[CodeGen]'
import { ConfirmTemplateDirective, ConfirmModalComponent } from '@appShared/components/confirm-modal-and-service';
import { ButtonComponent } from '@appShared/components/button/button.component';
import { environment } from '@appEnvironments/environment'

export enum NewStatusTypeCode {
   pause1Month = 1,
   pause2Months = 2,
   cancelSubscription = 3
}
export interface INewStatusType {
   code: NewStatusTypeCode
   description: string
   isCancel: boolean
   monthsToPause: number
   newStatusCode: number
   resumeDate: string
}

@Component({
    selector: 'app-account-membership',
    templateUrl: './membership.component.html',
    styleUrls: ['./membership.component.less'],
    //changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: true,
    imports: [NgIf, RouterLink, ButtonComponent, FormsModule, ReactiveFormsModule, NgFor, ConfirmTemplateDirective, ConfirmModalComponent, AsyncPipe, DatePipe]
})
export class AccountMembershipComponent {
   private _accountRoutes = environment.routes.member.account


   private _appFacade = inject(AppFacade)
   private _currentUser = this._appFacade.currentUser

   private _hybridService = inject(HybridWebviewInteropService)
   isDotNetInteroperable = toSignal(this._hybridService.isDotNetInteroperable$)

   private _subscription: ISubscription
   subscriptionIsAnnual: boolean
   subscriptionStatus: SubscriptionStatus_
   subscriptionIsActive: boolean
   subscriptionIsCancelled: boolean
   subscriptionIsEnded: boolean
   subscriptionIsPaused: boolean
   subscriptionIsOnHold: boolean
   subscriptionIsPendingPaused: boolean
   subscriptionIsPendingCancellation: boolean
   cancellationEffective: Date | string
   nextPaymentDue: Date | string
   pausedUntil: Date | string
   isSubmitting = signal<boolean>(false)
   mouseoverSubscriptionPlanSubmit = false
   accountMembershipUri = `${environment.baseUri}/${environment.routes.member.account.membership.uri}`

   isDotNetInteroperable$: Observable<boolean>
   updateSubscription: FormControl = new FormControl(false)
   updateSubscription$: Observable<boolean>
   updateSubscriptionText = signal<string>('')

   //isCancel$: Observable<boolean>
   showConfirmCancellation: boolean

   /* form/form-fields */
   subscriptionStatusForm: FormGroup
   newStatusTypeSelectFieldName = 'newStatusCode'
   newStatusTypeSelect: any = null
   newStatusTypes: INewStatusType[] = [
      {
         code: NewStatusTypeCode.pause1Month,
         description: 'Pause for 1 month',
         isCancel: false,
         monthsToPause: 1,
         newStatusCode: SubscriptionStatus_.Paused,
         resumeDate: ''
      },
      {
         code: NewStatusTypeCode.pause2Months,
         description: 'Pause for 3 months',
         isCancel: false,
         monthsToPause: 3,
         newStatusCode: SubscriptionStatus_.Paused,
         resumeDate: ''
      },
      {
         code: NewStatusTypeCode.cancelSubscription,
         description: 'Cancel Membership',
         isCancel: true,
         monthsToPause: 0,
         newStatusCode: SubscriptionStatus_.Cancelled,
         resumeDate: ''
      }
   ]
   cancellationReasonSelectFieldName = 'cancellationReasonCode'
   cancellationReasonSelect: any = null
   cancellationReasons = CancellationReasons
      .filter(reason => reason.code !== CancellationReason_.Unknown)
      .map(reason => {
         // setting isOther for showing CancellationReasonText
         reason['isOther'] = reason.code === CancellationReason_.Other
         return reason
      })
   cancellationReasonText: FormControl

   constructor(
      private _router: Router,
      private _commonService: CommonService,
      private _confirmService: ConfirmService,
      private _accountsService: AccountsService,
      urlService: UrlService,
      destroyRef: DestroyRef
   ) {
      urlService.setHeaderTitle(environment.routes.member.account.membership.title)

      this._createFormGroup()

      effect(() => {
         const subscriptions = this._currentUser()?.profile?.account?.subscriptions || []

         if (subscriptions.length) {
            this._subscription = subscriptions[0]
            this._setState(this._subscription)
         } else if (!this.isDotNetInteroperable()) {
            this._router.navigate([`/${this._accountRoutes.uri}`])
         }
      })

      this.updateSubscription$ = this.updateSubscription.valueChanges.pipe(
         takeUntilDestroyed(destroyRef),
         tap(isUpdateSubscription => {
            this._setUpdate(isUpdateSubscription)
         })
      )
   }

   /*
   * private methods
   * */

   private _createFormGroup(): void {
      this.cancellationReasonText = new FormControl(null)
      this.subscriptionStatusForm = new FormGroup({
         /* https://egghead.io/lessons/angular-create-a-formcontrol-dynamically-with-reactive-forms-in-angular */
         [this.newStatusTypeSelectFieldName]: new FormControl(
            null,
            Validators.required
         ),
         [this.cancellationReasonSelectFieldName]: new FormControl(null),
         cancellationReasonText: this.cancellationReasonText
      })
   }

   private _setState(subscription?: ISubscription) {
      if (subscription) {
         const subscriptionStatus = subscription.statusCode
         this.subscriptionStatus = subscriptionStatus
         this.subscriptionIsActive = subscriptionStatus === SubscriptionStatus_.Active
         this.subscriptionIsPaused = subscriptionStatus === SubscriptionStatus_.Paused
         this.subscriptionIsOnHold = subscriptionStatus === SubscriptionStatus_.OnHold
         this.subscriptionIsEnded = subscriptionStatus === SubscriptionStatus_.Ended
         this.subscriptionIsCancelled = subscriptionStatus === SubscriptionStatus_.Cancelled
         this.subscriptionIsPendingPaused = subscription.info.isPendingPause
         this.subscriptionIsPendingCancellation = subscription.info.isPendingCancellation
         this.cancellationEffective = subscription.cancellationEffective
         this.subscriptionIsAnnual = subscription.product?.billingFrequencyCode === BillingFrequency_.Annually
         this.nextPaymentDue = subscription.nextPaymentDue
         this.pausedUntil = subscription.pausedUntil

         /*********************************************/
         /* TODO - TESTING scenarios below - remove */
         /*********************************************/
         //this.subscriptionIsEnded = true
         //this.subscriptionIsActive = true
         //this.subscriptionIsPaused = true
         //this.subscriptionIsOnHold = true
         //this.subscriptionIsCancelled = true
         //this.subscriptionIsPendingPaused = true
         //this.subscriptionIsPendingCancellation = true
         //this.cancellationEffective = '5/25/24'
         //this.subscriptionIsAnnual = false
         //this.nextPaymentDue = '5/25/2024'
         //this.pausedUntil = '6/25/2024'
         /*********************************************/
         /* TODO - TESTING scenarios above - remove */
         /*********************************************/

         setTimeout(() => {
            this.updateSubscriptionText.set(this.subscriptionIsAnnual
               ? 'Cancel membership'
               : (this.subscriptionIsPaused || this.subscriptionIsPendingPaused)
                  ? 'Extend pause or cancel membership'
                  : 'Pause or cancel membership')
         },0)


         if (!this.subscriptionIsAnnual) {

            let pause1Month = this.newStatusTypes[0]
            let pause3Months = this.newStatusTypes[1]
            let isPausedContext = this.subscriptionIsPaused || this.subscriptionIsPendingPaused
            let paymentDue = isPausedContext && this.pausedUntil
               ? this.pausedUntil
               : this.nextPaymentDue

            let paymentDueMoment = this._commonService.dateTime.moment(new Date(paymentDue)).startOf('day')
            pause1Month.resumeDate =
               this._commonService.dateTime.formatDate(paymentDueMoment.clone().add(1, 'months')._d)
            pause3Months.resumeDate =
               this._commonService.dateTime.formatDate(paymentDueMoment.clone().add(3, 'months')._d)

            if (isPausedContext) {
               pause1Month.description = 'Extend for an additional month'
               pause3Months.description = 'Extend for an additional 3 months'
            } else {
               pause1Month.description = 'Pause for 1 month'
               pause3Months.description = 'Pause for 3 months'
            }
         }

      } else {
         this._router.navigate([`/${this._accountRoutes.uri}`])
      }
   }

   private _setUpdate(isUpdatemembership: boolean) {

      if (this.subscriptionIsAnnual) {
         this._commonService.setDynamicFormControlSelect(
            this,
            'newStatusTypeSelect',
            this.subscriptionStatusForm.controls[this.newStatusTypeSelectFieldName],
            this.newStatusTypes,
            NewStatusTypeCode.cancelSubscription,
            null,
            false
         )
      } else {
         this.newStatusTypeSelect = null
         this.subscriptionStatusForm.reset()
      }

      this._scrollToBottom()
   }

   private _scrollToBottom() {
      setTimeout(() => {
         this._commonService.scrollToAnchor('confirm-buttons')
      }, 0)
   }

   private _updateSubscription(subscriptionUpdateSubmission: ISubscriptionUpdateSubmission) {
      console.log('subscriptionUpdateSubmission:', subscriptionUpdateSubmission)

      if (subscriptionUpdateSubmission?.subscriptionId) {
         this.isSubmitting.set(true)

         this._accountsService
            .updateSubscription(subscriptionUpdateSubmission)
            .then((subscription: ISubscription) => {

               this._subscription = subscription
               this._setState(this._subscription)
               this.cancelNewSubscriptionStatus()
               this._setUpdate(false)

               this._appFacade.setUserSubscription(subscription)

               if (subscriptionUpdateSubmission.newStatusCode === SubscriptionStatus_.Cancelled) {
                  this._commonService.messageUser(
                     `<div class="text-center fs-5 text-uppercase">Membership Cancelled!</div>`
                  )
               } else {
                  this._commonService.messageUser(
                     `<div class="text-center fs-5 text-uppercase">Membership Updated!</div>`
                  )
               }
            })
            .catch(err => {
               console.log(err)
               if (err?.status === HttpStatusCode.PaymentRequired) {
                  let message = `${err.error}<br/><br/>
                                 There was an issue with the payment information we have on file.
                                 Please update your payment information`
                  this._commonService.dialogOK({
                     title: 'Payment Failed!',
                     message
                  })

                  this._router.navigate([`/${this._accountRoutes.billing.uri}`])
               }
            })
            .finally(() => this.isSubmitting.set(false))

      }
   }

   /*
   * public methods
   * */

   trackByCancellationTypeId: TrackByFunction<any> = (index, newStatusType) => newStatusType.id

   unPause() {
      let nextPaymentDue = this._commonService.dateTime.formatDate(this.nextPaymentDue)

      this._confirmService
         .confirm({
            title: 'Resume Membership',
            message: `<div class="fs-4">By clicking "Resume", your membership will resume and you will be charged
                     your regular scheduled payment on ${nextPaymentDue}<br/><br/>
                     Resume Membership?</div>`,
            yesText: 'Resume',
            yesButtonClass: 'btn-success'
         } as ConfirmOptions)
         .then(() => {
            this._updateSubscription({
               subscriptionId: this._subscription.id,
               newStatusCode: SubscriptionStatus_.Active,
               monthsToPause: 0
            } as ISubscriptionUpdateSubmission)
         })
         .catch(() => {
            /* Do Nothing */
         })
   }

   resume() {
      this._confirmService
         .confirm({
            title: 'Resume Membership',
            message: `<div class="fs-4">By clicking "Resume", your membership will resume immediately and your payment method on file
                     will be charged today. Your new billing date going forward will be based on today's payment.<br/><br/>
                     Resume Membership?</div>`,
            yesText: 'Resume',
            yesButtonClass: 'btn-success'
         } as ConfirmOptions)
         .then(() => {
            this._updateSubscription({
               subscriptionId: this._subscription.id,
               newStatusCode: SubscriptionStatus_.Active,
               monthsToPause: 0
            } as ISubscriptionUpdateSubmission)
         })
         .catch(() => {
            /* Do Nothing */
         })
   }

   undoPendingCancel() {
      let cancellationEffective = this._commonService.dateTime.formatDate(this.cancellationEffective)
      let annualComment = this.subscriptionIsAnnual
         ? `<div class="mb-3">If you take no action before the above date, your current plan will
                        become a month-to-month plan that can be cancelled at any time.</div>`
         : ''

      this._confirmService
         .confirm({
            title: 'Resume Membership',
            message: `<div class="fs-4 mb-3">By clicking "Resume", your membership will NOT be cancelled
                        and you will be charged your regular scheduled payment on ${cancellationEffective}</div>
                        ${annualComment}
                      <div class="fs-4">Resume Membership?</div>`,
            yesText: 'Resume',
            yesButtonClass: 'btn-success'
         } as ConfirmOptions)
         .then(() => {
            this._updateSubscription({
               subscriptionId: this._subscription.id,
               newStatusCode: SubscriptionStatus_.Active,
               monthsToPause: 0
            } as ISubscriptionUpdateSubmission)
         })
         .catch(() => {
            /* Do Nothing */
         })
   }

   setNewStatusStatus(newStatusType) {
      if (newStatusType) {
         this.newStatusTypeSelect = newStatusType

         this.subscriptionStatusForm.patchValue({
            [this.cancellationReasonSelectFieldName]: null
         })

         this.showConfirmCancellation = false

         this._scrollToBottom()
      }
   }

   setCancellationReason(cancellationReason) {
      if (cancellationReason) {
         this.cancellationReasonSelect = cancellationReason
         this.subscriptionStatusForm.patchValue({
            cancellationReasonText: null
         })
      }
   }

   setSubscription() {
      if (this.subscriptionStatusForm.valid) {
         this.isSubmitting.set(true)

         const subscriptionStatusFormValues = this.subscriptionStatusForm.value
         const newStatusCodeValue =
            subscriptionStatusFormValues[this.newStatusTypeSelectFieldName]
         const newStatusType = this.newStatusTypes.find(status => status.code === newStatusCodeValue)

         let finalConfirm = () => {

            const cancellationReasonCode =
               subscriptionStatusFormValues[this.cancellationReasonSelectFieldName]

            const subscriptionUpdateSubmission: ISubscriptionUpdateSubmission = {
               subscriptionId: this._subscription.id,
               newStatusCode: newStatusType.newStatusCode,
               monthsToPause: newStatusType.monthsToPause,
               cancellationReasonCode,
               cancellationReasonText: subscriptionStatusFormValues.cancellationReasonText
            } as ISubscriptionUpdateSubmission

            this._updateSubscription(subscriptionUpdateSubmission)
         }

         if (newStatusType.isCancel) {
            if (!this.showConfirmCancellation) {
               this.isSubmitting.set(false)
               this.showConfirmCancellation = true
               this._scrollToBottom()
            } else {
               finalConfirm()
            }
         } else {
            finalConfirm()
         }

      } else {
         this.isSubmitting.set(false)
         this._commonService.messageUser(
            'Please select a Membership Plan',
            null,
            ToastrType.error
         )
      }
   }

   cancelNewSubscriptionStatus() {
      this.updateSubscription.setValue(false)
   }

   externalNavigate(uri: string) {
      this._hybridService.externalNavigate(uri)
   }
}
